// src/SegmentAnalytics.js

import { useEffect } from 'react';

const SegmentAnalytics = () => {
  useEffect(() => {
    // Define and load the Segment analytics script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src =
      'https://cdn.segment.com/analytics.js/v1/%REACT_APP_SEGMENT_ID%/analytics.min.js';

    script.onload = () => {
      // Once the script is loaded, initialize Segment
      const analytics = window.analytics || [];
      if (!analytics.initialize) {
        if (analytics.invoked) {
          console.error('Segment snippet included twice.');
        } else {
          analytics.invoked = true;
          analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
          ];

          analytics.factory = (method) => {
            return (...args) => {
              args.unshift(method);
              analytics.push(args);
              return analytics;
            };
          };

          for (let method of analytics.methods) {
            analytics[method] = analytics.factory(method);
          }

          analytics.load('%REACT_APP_SEGMENT_ID%');
        }
      }
    };

    document.body.appendChild(script);

    // Cleanup on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array to ensure this runs only once when the component mounts

  return null; // This component doesn't render anything to the UI
};

export default SegmentAnalytics;
